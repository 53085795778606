import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { WorkComponent } from './work/work.component';
import { ContactComponent } from './contact/contact.component';
import { BankingComponent } from './banking/banking.component';
import { HostedVoiceComponent } from './hosted-voice/hosted-voice.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { FMCGComponent } from './fmcg/fmcg.component';
import { SaleComponent } from './sale/sale.component';
import { GovernmentComponent } from './government/government.component';
import { FAQsComponent } from './faqs/faqs.component';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';
import { PeopleComponent } from './people/people.component';
import { PlatformComponent } from './platform/platform.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PreloadComponent } from './preload/preload.component';
import { ManagedSecurityServiceComponent } from './managed-security-service/managed-security-service.component';
import { SocAsAServiceComponent } from './soc-as-a-service/soc-as-a-service.component';
import { PenetrationTestingComponent } from './penetration-testing/penetration-testing.component';
import { SecurityComplianceAuditingComponent } from './security-compliance-auditing/security-compliance-auditing.component';
import { EndpointSecurityServiceComponent } from './endpoint-security-service/endpoint-security-service.component';
import { NetworkSecurityManagementComponent } from './network-security-management/network-security-management.component';
import { SecurityEngineeringComponent } from './security-engineering/security-engineering.component';
import { SecurityAwarenessProgramComponent } from './security-awareness-program/security-awareness-program.component';
import { MalwareAnalysisComponent } from './malware-analysis/malware-analysis.component';
// import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { CaseStudyComponent } from './case-study/case-study.component';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';
import { ContactusEmailService } from './APIservices/emailSender/contactus-email.service';
import { PartnersComponent } from './partners/partners.component';
import { NewsletterComponent } from './newsletter/newsletter.component';

import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    WorkComponent,
    ContactComponent,
    BankingComponent,
    HostedVoiceComponent,
    ECommerceComponent,
    FMCGComponent,
    SaleComponent,
    GovernmentComponent,
    FAQsComponent,
    InfrastructureComponent,
    PeopleComponent,
    PlatformComponent,
    HeaderComponent,
    FooterComponent,
    PreloadComponent,
    ManagedSecurityServiceComponent,
    SocAsAServiceComponent,
    PenetrationTestingComponent,
    SecurityComplianceAuditingComponent,
    EndpointSecurityServiceComponent,
    NetworkSecurityManagementComponent,
    SecurityEngineeringComponent,
    SecurityAwarenessProgramComponent,
    MalwareAnalysisComponent,
    CaseStudyComponent,
    PartnersComponent,
    NewsletterComponent,
  ],
  imports: [
  // FormControl,
  FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  providers: [
    ContactusEmailService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
