import { Component, OnInit } from '@angular/core';
import { PageService } from '../APIservices/page/page.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  pageId = 18;
  pageData: any = {};

  constructor(private contentService: PageService) { }

  ngOnInit(): void {
    this.pageById();
  }

  pageById() {
    let body = {
      "pageid": this.pageId
    }
    

    this.contentService.getPageById(body).subscribe((res) => {
      this.pageData = res.data.content.data;
    });
  }
}
