import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { CaseService } from '../APIservices/case/case.service';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.css']
})
export class CaseStudyComponent implements OnInit {

  caseData: any;

  constructor(private caseService: CaseService, public router: Router, private activeRoute: ActivatedRoute) { 
  }

  ngOnInit() {

    let body = {
      "caseid": this.activeRoute.snapshot.params['id']
    }

    this.caseService.getOneCase(body).subscribe((res) => {
      console.log('res ', res);
      
      if (res.status == true) {
        this.caseData = res.data;
        console.log(this.caseData);
        
      } else {
        this.router.navigate(['/our-work']);
      }
      
    });


  }

}
