import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  dropDown: boolean = false;

  constructor(private spinner: NgxSpinnerService) { }
  
  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
  }

  ngAfterViewInit() {
  }

  showHideDropdown() {
    this.dropDown = !this.dropDown;
  }
}
