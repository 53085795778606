import { Component, OnInit } from '@angular/core';
import { PageService } from '../APIservices/page/page.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  pageId = 4;
  pageData: any = {};

  constructor(private contentService: PageService) { }

  ngOnInit() {
    this.pageById();
  }

  getAllPages() {
    this.contentService.getAllPages().subscribe((res) => {
      console.log(res);
    })
  }

  pageById() {
    let body = {
      "pageid": this.pageId
    }

    this.contentService.getPageById(body).subscribe((res) => {
      // console.log(res);
      this.pageData = res.data.content.data;
      console.log("pagedata => ", this.pageData);
      
    });
  }

}
