import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { PageService } from '../APIservices/page/page.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, AfterViewInit {

  pageId = 5;
  pageData: any = {};

  constructor(private contentService: PageService) { 
    this.pageById();
  }

  ngOnInit() {
    // this.pageById();
  }

  ngAfterViewInit() {
    // this.pageById();
  }

  pageById() {
    let body = {
      "pageid": this.pageId
    }
    

    this.contentService.getPageById(body).subscribe((res) => {
      // console.log(res);
      this.pageData = res.data.content.data;
      console.log("pagedata => ", this.pageData);
      
    });
  }

}
