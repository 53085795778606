import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaseService {

  oneCaseData: any = {};

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  apiUrl = environment.BaseAPI_URL;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
  }

  getAllCases(): Observable<any> {
    return this.http.get(`${this.apiUrl}/cases/allcases`, this.httpOptions)
    .pipe(map((res) => {
      return res
    }));
  }

  getOneCase(caseObj): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/cases/casebyid`, caseObj, this.httpOptions)
    .pipe(map((res) => {
      return res
    }));
  }

}
