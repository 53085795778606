import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BankingComponent } from './banking/banking.component';
import { ContactComponent } from './contact/contact.component';
import { FAQsComponent } from './faqs/faqs.component';
import { FMCGComponent } from './fmcg/fmcg.component';
import { FooterComponent } from './footer/footer.component';
import { GovernmentComponent } from './government/government.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { HostedVoiceComponent } from './hosted-voice/hosted-voice.component';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';
import { ManagedSecurityServiceComponent } from './managed-security-service/managed-security-service.component';
import { PeopleComponent } from './people/people.component';
import { PlatformComponent } from './platform/platform.component';
import { SaleComponent } from './sale/sale.component';
import { ServicesComponent } from './services/services.component';
import { SocAsAServiceComponent } from './soc-as-a-service/soc-as-a-service.component';
import { PenetrationTestingComponent } from './penetration-testing/penetration-testing.component';
import { SecurityComplianceAuditingComponent } from './security-compliance-auditing/security-compliance-auditing.component';
import { EndpointSecurityServiceComponent } from './endpoint-security-service/endpoint-security-service.component';
import { NetworkSecurityManagementComponent } from './network-security-management/network-security-management.component';
import { SecurityEngineeringComponent } from './security-engineering/security-engineering.component';
import { SecurityAwarenessProgramComponent } from './security-awareness-program/security-awareness-program.component';
import { MalwareAnalysisComponent } from './malware-analysis/malware-analysis.component';
import { WorkComponent } from './work/work.component';
import { CaseStudyComponent } from './case-study/case-study.component';

// const routes: Routes = [];



const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  
  },{
    path: 'about-us',
    component:AboutComponent ,
  
  },{
    path: 'services',
    component: ServicesComponent,
  
  },{
    path: 'our-work',
    component: WorkComponent,
    
  },{
    path: 'contact-us',
    component: ContactComponent,
  
  },{
    path: 'FAQs',
    component: FAQsComponent,
  
  },{
    path: 'banking-finacials-solutions',
    component: BankingComponent,
  
  },{
    path: 'hosted-voice',
    component: HostedVoiceComponent,
  
  },{
    path: 'FMCG-&-manufacturing',
    component:FMCGComponent ,
  
  },{
    path: 'sales-&-marketing',
    component: SaleComponent,
  
  },{
    path: 'GOV',
    component: GovernmentComponent,
  
  },{
    path: 'infrastructure',
    component: InfrastructureComponent,
  
  },{
    path: 'people',
    component: PeopleComponent,
  
  },{
    path: 'platform',
    component: PlatformComponent,
  
  }
  ,{
    path: 'header',
    component: HeaderComponent,
  
  }
  ,{
    path: 'footer',
    component: FooterComponent,
  
  },

  {
    path: 'managed-security-service',
    component: ManagedSecurityServiceComponent,
  
  },

  {
    path: 'soc-as-a-service',
    component: SocAsAServiceComponent,
  },

  {
    path: 'penetration-testing',
    component: PenetrationTestingComponent,
  },

  {
    path: 'security-compliance-auditing',
    component: SecurityComplianceAuditingComponent,
  },

  {
    path: 'endpoint-security-service',
    component: EndpointSecurityServiceComponent,
  },

  {
    path: 'network-security-management',
    component: NetworkSecurityManagementComponent,
  },

  {
    path: 'security-engineering',
    component: SecurityEngineeringComponent,
  },

  {
    path: 'security-awareness-program',
    component: SecurityAwarenessProgramComponent,
  },

  {
    path: 'malware-analysis',
    component: MalwareAnalysisComponent,
  },

  {
    path: 'casestudy/:id',
    component: CaseStudyComponent,

  },


  {
    path: '**',
    redirectTo: '', pathMatch: 'full'
    // component: HomeComponent,
    // resolve: { data: HomeService }
  }];

  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }