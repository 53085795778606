import { Component, OnInit } from '@angular/core';
import { PageService } from '../APIservices/page/page.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  pageId = 17;
  pageData: any = {};

  constructor(private contentService: PageService) { }

  ngOnInit(): void {
    this.pageById();
  }

  pageById() {
    let body = {
      "pageid": this.pageId
    }
    

    this.contentService.getPageById(body).subscribe((res) => {
      this.pageData = res.data.content.data;
    });
  }

}
