import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  apiUrl = environment.BaseAPI_URL;

  constructor(private http: HttpClient) { }

  getAllPages():Observable<any> {
    return this.http.get(`${this.apiUrl}/pages/pagecontent`, this.httpOptions)
    .pipe(map((res) => {
      return res
    }));
  }

  getPageById(page): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/pages/pagebyid`, page, this.httpOptions)
    .pipe(map((res) => {
      return res
    }));
  }
}
