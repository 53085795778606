import { state } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CaseService } from '../APIservices/case/case.service';
import { PageService } from '../APIservices/page/page.service';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {
  pageId = 16;
  pageData: any = {};
  caseData: any;
  oneCase: any;

  constructor(private contentService: PageService, private caseService: CaseService, public router: Router) { }

  ngOnInit() {
    this.pageById();
    this.allCases();
  }

  pageById() {
    let body = {
      "pageid": this.pageId
    }

    this.contentService.getPageById(body).subscribe((res) => {
      // console.log(res);
      this.pageData = res.data.content.data;
      console.log("pagedata => ", this.pageData);
      
    });
  }

  allCases() {
    this.caseService.getAllCases().subscribe((res) => {
      this.caseData = res.data;
      console.log("caseData => ", this.caseData);
    })
  }

}
