import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'web-CS';
  loading:boolean = false;
  constructor(private router: Router) {
    //subscribes every changes of your route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }

      if (event instanceof NavigationEnd) {
        setTimeout(() => { // here
          this.loading = false;
        }, 2000);
        //scroll to top
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationCancel) {
        setTimeout(() => { // here
          this.loading = false;
        }, 2000);
      }

      if (event instanceof NavigationError) {
        setTimeout(() => { // here
          this.loading = false;
        }, 2000);
      }
    });
  }
}
