import { Component, OnInit } from '@angular/core';
import { PageService } from '../APIservices/page/page.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactusEmailService } from '../APIservices/emailSender/contactus-email.service';

@Component({
  selector: 'app-security-engineering',
  templateUrl: './security-engineering.component.html',
  styleUrls: ['./security-engineering.component.css']
})
export class SecurityEngineeringComponent implements OnInit {
  userForm: FormGroup;
  emailBody: any = {
    "email": "",
    "subject": "",
    "message": "",
    "name": ""
  }
  isValidate: boolean = false;
  submitted: boolean = false
  responseMessage: any;
  pageId = 13;
  pageData: any = {};

  constructor(private formBuilder: FormBuilder, private ThankyouContactingusEmailSender: ContactusEmailService, private contentService: PageService) {
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ])),
      message: ['', [Validators.required]],
      subject: ['', [Validators.required]]
    });

  }

  ngOnInit() {
    this.pageById();
  }

  pageById() {
    let body = {
      "pageid": this.pageId
    }
    
    this.contentService.getPageById(body).subscribe((res) => {
      this.pageData = res.data.content.data;
      console.log("pagedata => ", this.pageData);
    });
  }

  get f() {
    // console.log('dasdas', this.userForm.em);
    if (this.userForm.status === 'VALID') { this.isValidate = true } else { this.isValidate = false }
    return this.userForm.controls;
  }
  sendEmail() {
    console.log(this.isValidate);
    console.log(this.userForm.status);
    this.emailBody.email = this.userForm.value.email
    this.emailBody.name = this.userForm.value.name
    this.emailBody.subject = this.userForm.value.subject
    this.emailBody.message = this.userForm.value.message
    this.ThankyouContactingusEmailSender.SendEmail(this.emailBody).subscribe((res) => {
      console.log('email send', res.status);
      if (res.status == 1) {
        this.submitted = true;
        this.responseMessage = "Thank you for contacting us. Confirmation email has been sent on given address."
        this.clearForm()
      } else {
        this.submitted = false;
        this.responseMessage = "Sorry, something went wrong. Please try again later."
      }

    })

  }
  clearForm() {

    this.userForm.reset();
  }

}
