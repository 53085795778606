import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import { map } from 'rxjs/operators';
// import 'rxjs/add/operator/catch';


@Injectable({
  providedIn: 'root'
})
export class ContactusEmailService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http : HttpClient) { }
  SendEmail(user: any): Observable<any> {
    return this.http.post<any>(environment.BaseAPI_URL + '/contactus', user, this.httpOptions)
    .pipe(map(res => { return res }));
  }
}
