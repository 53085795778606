import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hosted-voice',
  templateUrl: './hosted-voice.component.html',
  styleUrls: ['./hosted-voice.component.css']
})
export class HostedVoiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
