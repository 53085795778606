import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fmcg',
  templateUrl: './fmcg.component.html',
  styleUrls: ['./fmcg.component.css']
})
export class FMCGComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
